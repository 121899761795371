











import Vue from "vue";
import { vueWindowSizeMixin } from "vue-window-size/option-api";

import { XL_THRESHOLD } from "@/utils/constants";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    showValidIcon: {
      type: Boolean,
      default: false,
    },
  },
  name: "MemberApplicationFormRegion",
  computed: {
    logoSize(): number {
      return this.$windowWidth > XL_THRESHOLD ? 40 : 20;
    },
  },
  mixins: [vueWindowSizeMixin()],
});
