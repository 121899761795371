










import Vue from "vue";
import { mapActions } from "vuex";

import { SubscriptionTopic } from "@/types/Subscription";
import MemberApplicationFormRegion from "@/components/member/ApplicationFormRegion.vue";

import {
  listSubscriptionOptions,
  updateSubscriptionOption,
} from "@/utils/backend";

export default Vue.extend({
  components: {
    MemberApplicationFormRegion,
  },
  name: "Subscription",
  data: () => {
    return {
      options: [] as Array<SubscriptionTopic>,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading", "displayMessage"]),
    async fetch() {
      this.showLoading();

      const results = await listSubscriptionOptions();
      this.options = results;

      this.hideLoading();
    },
    async save() {
      this.showLoading();

      for (const option of this.options) {
        await updateSubscriptionOption(option.id, option);
      }

      this.displayMessage(
        "Your Subscription configuration was modified successfully"
      );
      this.hideLoading();
    },
  },
});
